import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';

@Component({
    selector: 'app-layout-vw',
    templateUrl: './layout-vw.component.html',
    styleUrls: ['./layout-vw.component.scss'],
})
export class LayoutVwComponent implements OnInit {
    private mainContent: HTMLElement | null = null;
    currentDateTime: string | null | undefined;

    constructor(private router: Router) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                if (this.mainContent) {
                    this.mainContent!.scrollTop = 0;
                }
            }
        });
    }

    ngOnInit(): void {
        this.mainContent = document.getElementById('main-content');
    }
}
