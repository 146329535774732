<uikit-page title="Home">
    <div
        class="absolute inset-0 flex items-center justify-center opacity-10 pointer-events-none text-6xl text-gray-400 space-x-4"
        *ngIf="branchName"
    >
        <img
            src="assets/icons/simple-icons_volkswagen-brand.png"
            alt="Branch Icon"
            class="w-12 h-12"
        />
        {{ branchName }}
    </div>
</uikit-page>
