<div class="fixed " style="top:0;left:0;width: 100vw;z-index: 9999">
    <p-toast position="top-center" key="uikit-messages" />
    <p-dialog header="{{confirmOptions.title}}" [modal]="true" [(visible)]="confirmShow"
        [style]="{'max-width':'25rem',margin:'10px'}" [closable]="false" [closeOnEscape]="false">
        <span class="p-text-secondary block mb-5">{{confirmOptions.message}}</span>
        <div class="flex justify-content-end gap-2">
            <uikit-button (onClick)="confirmData.reject()" typeColor="cancel" format="outlined">CANCELAR</uikit-button>
            <uikit-button (onClick)="confirmData.accept()"
                typeColor="{{confirmOptions.color??'primary'}}">CONFIRMAR</uikit-button>
        </div>
    </p-dialog>
</div>