import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http';

import { Observable, catchError, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
    GetChecklistAttachment,
    GetChecklistAttachmentResponse,
} from '../models/receiptDetail.model';
import { ApiService } from './api.service';

@Injectable()
export class ImageService extends ApiService {

    async getChecklistAttachmentPromise(id: number, checklistId: number, checklistItemId: number): Promise<any> {
        return (await this.postPromise<any>('/checklist/attachments', { id, checklistId, checklistItemId }))?.attachment
    }

    getLoader(id: number, checkListId: number, checkListItemId: number) {
        return async () => "data:image/png;base64," + (await this.getChecklistAttachmentPromise(id, checkListId, checkListItemId))
    }


}
