import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SubMenuItem } from 'src/app/core/models/menu.model';
import { MenuService } from '../../../../core/service/menu.service';

@Component({
    selector: 'app-sidebar-custom-menu',
    templateUrl: './sidebar-menu.component.html',
    styleUrls: ['./sidebar-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarMenuComponent implements OnInit {
    constructor(public menuService: MenuService) {}

    public toggleMenu(subMenu: SubMenuItem) {
        this.menuService.toggleMenu(subMenu);
    }

    ngOnInit(): void {}
}
