import { NgModule } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NavbarMobileComponent } from './navbar-mobile.component';
import { NavbarMobileMenuModule } from './navbar-mobile-menu/navbar-mobile-menu.module';

@NgModule({
    declarations: [NavbarMobileComponent],
    imports: [
        CommonModule,
        NgClass,
        AngularSvgIconModule,
        NavbarMobileMenuModule,
    ],
    exports: [NavbarMobileComponent],
})
export class NavbarMobileModule {}
