<div class="flex h-12 items-center bg-white dark:bg-night-700">
    <div
        class="flex w-full items-center justify-between px-7 text-[11px] font-medium"
    >
        <div class="text-gray-400 dark:text-night-300">
            <span class="mr-2">{{ year }}©</span>
            <a
                href=""
                target="_blank"
                class="font-normal text-gray-800 hover:text-primary-500 dark:text-night-100 dark:hover:text-primary-500"
            >
                T-Systems. All Rights Reserved.
            </a>
        </div>
        <ul class="flex text-gray-500 dark:text-night-300">
            <li class="hover:text-primary-500">
                <a
                    href="https://www.simmmple.com/licenses"
                    target="_blank"
                    class="menu-link px-2"
                    >License</a
                >
            </li>
            <li class="hover:text-primary-500">
                <a
                    href="https://www.simmmple.com/terms-of-service"
                    target="_blank"
                    class="menu-link px-2"
                    >Terms of use</a
                >
            </li>
        </ul>
    </div>
</div>
