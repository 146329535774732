export const environment = {
    production: false,

    helperResponsive: true,
    apiUrl: 'https://vtlog-service-dev.azurewebsites.net',
    keycloak: {
        issuer: 'https://eevnapi-deve.t-systems.com.br/auth',
        realm: 'vtlog',
        clientId: 'vtlog-web',
        audience: 'vtlog-resource-server-client',
        initOptions: {
            onLoad: 'login-required', // allowed values 'login-required', 'check-sso';
            flow: 'standard',
        },
    },
    googleMapsApiKey: 'AIzaSyD5kC7WyVbYOElM64p5W0sKGDufd2Df4Ps',
    mapId: 'a92f95aa7a712ee4',
    featureToggle: {
        AdminUser: false,

        locationScreen: false,
    },
    secretKey: 'dac2ec68973ff31e3473312df14b5d99c114dc20',
    report: {
        dashboard: {
            appRate: "11e4ffe7-024f-4b73-b347-b936b0642b17"
        }
    }
};
