<div class="sticky bg-white dark:bg-night-700 shadow-md backdrop:blur-xl z-10">
    <div class="mx-auto px-6">
        <div class="flex py-4 items-center justify-between md:justify-start">
            <!-- Mobile Navigation Menu Button-->
            <div class="sm:order-1 md:hidden">
                <button
                    (click)="toggleMobileMenu()"
                    type="button"
                    class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 hover:bg-gray-100 hover:text-gray-500 dark:bg-night-700 dark:text-night-200 dark:hover:text-night-400"
                    aria-expanded="false"
                >
                    <span class="sr-only">Open menu</span>
                    <!-- Heroicon name: outline/menu -->
                    <svg-icon
                        src="assets/icons/heroicons/outline/menu.svg"
                        [svgClass]="'h-6 w-6'"
                    >
                    </svg-icon>
                </button>
            </div>

            <!-- Logo -->
            <div
                class="flex items-center justify-start sm:order-2 md:mr-10 lg:hidden"
            >
                <a
                    class="flex items-center justify-center rounded p-2 focus:outline-none focus:ring-1"
                >
                    <svg-icon
                        src="assets/icons/simple-icons_volkswagen-brand.svg"
                    >
                        >
                    </svg-icon>
                </a>
                <b class="pl-3 text-sm font-bold dark:text-night-50 sm:block">
                    Vehicle Tracking
                </b>
            </div>

            <!-- Profile menu -->
            <div
                class="items-center justify-end sm:order-4 md:flex md:flex-1 lg:w-0"
            >
                <app-profile-menu></app-profile-menu>
            </div>
        </div>
    </div>
    <!-- Mobile menu -->
    <app-navbar-mobile></app-navbar-mobile>
</div>
