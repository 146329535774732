import { Injectable } from '@angular/core';
import { ReceiptDetailsModel } from '../models/receiptDetail.model';
import { ApiService } from './api.service';

@Injectable()
export class ReceiptService extends ApiService {

    async getReceiptById(id: string): Promise<ReceiptDetailsModel> {
        return ReceiptDetailsModel.from((await this.getPromise<any>(`/receipt/details/${id}`)).vehicleDetails);

    }


}
