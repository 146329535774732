import { NgModule } from '@angular/core';
import {
    CommonModule,
    NgClass,
    NgFor,
    NgTemplateOutlet,
} from '@angular/common';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SidebarSubmenuComponent } from './sidebar-submenu.component';

@NgModule({
    declarations: [SidebarSubmenuComponent],
    imports: [
        CommonModule,
        NgClass,
        NgFor,
        NgTemplateOutlet,
        RouterLinkActive,
        RouterLink,
        AngularSvgIconModule,
    ],
    exports: [SidebarSubmenuComponent],
})
export class SidebarSubmenuModule {}
