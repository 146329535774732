export class Permissions {
    // Resources Consulta de Veículos
    static readonly WEB_MODULE_LIST_VEHICLES = 'list-vehicles';
    static readonly WEB_MODULE_LIST_VEHICLES_EXPORT = 'list-vehicles-export';
    static readonly WEB_MODULE_VEHICLE_DETAILS = 'vehicle-details';
    static readonly WEB_MODULE_VEHICLE_ORDER_DETAILS = 'vehicle-order-details';
    static readonly WEB_MODULE_VEHICLE_RECEIPT_DETAILS =
        'vehicle-receipt-details';
    static readonly WEB_MODULE_VEHICLE_LOCATIONS_DETAILS =
        'vehicle-locations-details';

    //  Resource localização de veiculos
    static readonly WEB_MODULE_VEHICLES_LOCATIONS = 'list-vehicles-locations';

    //  Resource devolução de veiculos
    static readonly WEB_MODULE_VEHICLE_RETURN = 'list-vehicles-return';

    //  Resource sinistro
    static readonly WEB_MODULE_VEHICLE_INSURANCE = 'list-vehicles-insurance';

    static readonly WEB_MODULE_DASHBOARD = 'dashboard';

    // Dash App rate
    static readonly WEB_MODULE_DASHBOARD_APP_RATE = 'dashboard-app-rate';
    static readonly WEB_MODULE_RELATORIOS = 'relatorios';

    //  Resource adminstração de usuarios
    static readonly WEB_MODULE_USERS_SETTINGS = 'users-settings';

    //  Resource parametrização
    static readonly WEB_MODULE_SETTINGS = 'settings';

    static readonly WEB_MODULE_USERS_PROFILE = 'users-profile';
    static readonly WEB_MODULE_USERS_HELP = 'users-help';

    // Authorization Scopes
    static readonly WEB_MODULE_DEV = 'dev';
    static readonly WEB_MODULE_ADMIN = 'admin';
    static readonly WEB_MODULE_MANAGER = 'manage';
    static readonly WEB_MODULE_VIEW = 'view';
}
