<div class="flex h-screen w-full overflow-hidden">
    <!-- Sidebar -->
    <app-sidebar-custom></app-sidebar-custom>

    <div
        class="flex grow flex-col content-start overflow-hidden bg-gray-100 dark:bg-night-800"
    >
        <!-- Header -->
        <app-navbar-vw></app-navbar-vw>
        <!-- main content -->
        <div
            id="main-content"
            class="scrollbar-thumb-rounded scrollbar-track-rounded grow overflow-auto scrollbar-thin scrollbar-thumb-white scrollbar-track-gray-100 dark:scrollbar-thumb-night-700 dark:scrollbar-track-night-800"
        >
            <router-outlet></router-outlet>
        </div>

        <!-- footer -->
        <!-- <app-footer></app-footer> -->

        <!-- Bottom bar -->
        <!-- <app-bottom-navbar></app-bottom-navbar> -->
    </div>
</div>
