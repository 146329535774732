import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import packageJson from '../../../../../package.json';

import { UikitPage } from 'src/app/shared/uikit/uikit-page/index.component';
import { UikitButton } from 'src/app/shared/uikit/uikit-button/index.component';

@Component({
    selector: 'app-home',
    standalone: true,
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
    imports: [CommonModule, UikitPage, UikitButton, RouterModule],
})
export class HomeComponent {
    public appJson: any = packageJson;
    branchName: string | null;

    constructor() {
        this.branchName = this.appJson.displayName;
    }
}
