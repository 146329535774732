import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
    selector: 'app-profile-menu',
    templateUrl: './profile-menu.component.html',
    styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit {
    public isMenuOpen = false;

    constructor(private authService: AuthService) {}

    userProfile: User = {} as User;
    fullName: string = '';
    initials: string = '';

    ngOnInit(): void {
        this.loadUserProfile();
        this.fullName =
            this.userProfile.firstName + ' ' + this.userProfile.lastName;
        this.initials = this.getInitials(this.fullName);
    }

    public toggleMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
    }

    loadUserProfile() {
        return (this.userProfile = JSON.parse(
            localStorage.getItem('keycloak::user_profile') ?? '{}'
        ));
    }

    getInitials(name: string): string {
        const nameParts = name.split(' ');
        const initials =
            nameParts[0].charAt(0).toUpperCase() +
            nameParts[nameParts.length - 1].charAt(0).toUpperCase();
        return initials;
    }

    logout() {
        this.authService.logout();
    }
}
