import { PrimeNGConfig } from "primeng/api/primengconfig";

import LocalePtBr from 'primelocale/pt-br.json'
import LocaleEn from 'primelocale/en.json'
import LocaleEs from 'primelocale/es.json'

const locales = {
    'pt-BR':LocalePtBr["pt-br"],
    'en':LocaleEn["en"],
    'es':LocaleEs['es']
}


import { Translation } from "primeng/api/translation";


const PrimeNgConfigsInit = (config: PrimeNGConfig) => {
    config.ripple = true;
    config.setTranslation(locales["pt-BR"] as Translation);
}

export default PrimeNgConfigsInit