import { NgModule } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { ResponsiveHelperComponent } from './responsive-helper.component';

@NgModule({
    declarations: [ResponsiveHelperComponent],
    imports: [CommonModule, NgIf],
    exports: [ResponsiveHelperComponent],
})
export class ResponsiveHelperModule {}
