<div class="pt-4" *ngFor="let menu of menuService.pagesMenu">
    <!-- <div class="mx-1 mb-2 flex items-center justify-between">
    <small class="text-xs font-semibold text-gray-400 dark:text-night-200">
      {{ menu.group }}
    </small>
  </div> -->
    <ul class="flex flex-col space-y-1">
        <!-- List items -->
        <li *ngFor="let item of menu.items">
            <!-- Menu List -->
            <div
                (click)="toggleMenu(item)"
                class="group relative text-gray-400 dark:text-night-400"
            >
                <!-- Icon -->
                <div
                    [ngClass]="item.active ? 'text-primary-500' : ''"
                    class="pointer-events-none absolute m-2"
                >
                    <svg-icon src="{{ item.icon }}" [svgClass]="'h-5 w-5'">
                    </svg-icon>
                </div>

                <!-- Condition -->
                <ng-container
                    [ngTemplateOutlet]="item.children ? childMenu : parentMenu"
                    [ngTemplateOutletContext]="{ item: item }"
                >
                </ng-container>

                <!-- Workaround:: Enable routerLink -->
                <ng-template #parentMenu let-item="item">
                    <div
                        routerLink="{{ item.route }}"
                        class="flex h-9 cursor-pointer items-center justify-start rounded text-gray-600 hover:bg-gray-100 dark:text-night-100 dark:hover:bg-night-600"
                    >
                        <a
                            routerLinkActive="text-primary-500"
                            (click)="closeMenu()"
                            class="ml-10 truncate text-xs font-semibold tracking-wide focus:outline-none"
                        >
                            {{ item.label }}
                        </a>
                    </div>
                </ng-template>

                <!-- Workaround:: Disable routerLink -->
                <ng-template #childMenu let-item="item">
                    <div
                        class="flex h-9 cursor-pointer items-center justify-start rounded hover:bg-gray-100 dark:hover:bg-night-600"
                    >
                        <a
                            class="ml-10 truncate text-xs font-semibold tracking-wide text-gray-600 focus:outline-none dark:text-night-100"
                        >
                            {{ item.label }}
                        </a>
                    </div>
                </ng-template>

                <!-- Arrow Icon -->
                <button
                    *ngIf="item.children"
                    [ngClass]="{
                        hidden: !menuService.showSideBar,
                        'rotate-90': item.expanded
                    }"
                    class="pointer-events-none absolute top-1 right-0 flex items-center p-1 text-gray-400 transition-all duration-500 dark:text-night-400"
                >
                    <svg-icon
                        src="assets/icons/heroicons/solid/chevron-right.svg"
                        [svgClass]="'h-5 w-5'"
                    >
                    </svg-icon>
                </button>
            </div>

            <!-- Submenu items -->
            <app-navbar-mobile-submenu
                [submenu]="item"
            ></app-navbar-mobile-submenu>
        </li>
    </ul>
</div>
