import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http';

import { Observable, catchError, retry, throwError } from 'rxjs';
import {
    CheckListTemplateDTO,
    GetReceiptCheckListTemplateItemQuery,
    GetReceiptCheckListTemplateItemViewModel,
} from '../models/checkList.module';
import { environment } from 'src/environments/environment';
import {
    GenerateReportCheckList,
    GenerateReportCheckListResponse,
} from '../models/receiptDetail.model';
import { ApiService } from './api.service';

@Injectable()
export class ChecklistService extends ApiService {

    getChecklistTemplateItem(
        paramsFilter: GetReceiptCheckListTemplateItemQuery
    ): Observable<GetReceiptCheckListTemplateItemViewModel> {
        const url = `${environment.apiUrl}/checklist/template/item`;

        return this.http
            .post<GetReceiptCheckListTemplateItemViewModel>(url, paramsFilter)
            .pipe(retry(0), catchError(this.handleError));
    }

    async getChecklistTemplateItemsPromise(paramsFilter: GetReceiptCheckListTemplateItemQuery): Promise<CheckListTemplateDTO> {
        return (await this.postPromise<GetReceiptCheckListTemplateItemViewModel>("/checklist/template/item", paramsFilter)).checkListTemplate
    }

    async getChecklistReceipt(checklistId: number, checklistTemplateId: number): Promise<any> {
        return (await this.postPromise<any>("/checklist/template", { checklistId, checklistTemplateId })).checkListTemplate
    }

    generatePdfReportCheckList(
        checkList: GenerateReportCheckList,
        attachment: boolean
    ): Observable<GenerateReportCheckListResponse> {
        return this.http
            .post<GenerateReportCheckListResponse>(
                `${environment.apiUrl}/receiptInspectionReport`,
                {
                    ...checkList,
                    "getAttachments": attachment
                }
            )
            .pipe(retry(0), catchError(this.handleError));
    }

    generatePdfReportCheckListPromise(
        checkListId: number,
        checkListTemplateId: number,
        attachment: boolean
    ): Promise<GenerateReportCheckListResponse> {

        return this.postPromise("/receiptInspectionReport", {
            checkListId,
            checkListTemplateId,
            "getAttachments": attachment
        })
    }

}
