import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutVwComponent } from './layout-vw.component';
import { NavbarModule } from '../../shared/uikit/navbar/navbar.module';
import { FooterComponent } from '../../shared/uikit/footer/footer.component';
import { RouterModule, RouterOutlet } from '@angular/router';
import { SidebarModule } from 'src/app/shared/uikit/sidebar/sidebar.module';

@NgModule({
    declarations: [LayoutVwComponent],
    exports: [LayoutVwComponent],
    imports: [
        CommonModule,
        NavbarModule,
        SidebarModule,
        FooterComponent,
        RouterOutlet,
        RouterModule,
    ],
})
export class LayoutVwModule {}
