<nav
    [ngClass]="menuService.showSideBar ? 'w-52 xl:w-72 2xl:w-72' : 'w-[72px]'"
    class="scrollbar-thumb-rounded scrollbar-track-rounded hidden h-full flex-col justify-between overflow-x-auto bg-primary-950 pt-3 transition-all duration-300 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-100 dark:bg-night-700 dark:scrollbar-thumb-night-500 dark:scrollbar-track-night-600 lg:flex"
>
    <div class="px-4">
        <!-- Logo -->
        <div class="relative">
            <div class="flex items-center" *ngIf="menuService.showSideBar">
                <a
                    href="/"
                    class="flex items-center justify-center rounded p-2 focus:outline-none focus:ring-1"
                >
                    <svg-icon
                        src="assets/icons/simple-icons_volkswagen.svg"
                        [svgClass]="'h-9 w-9'"
                    >
                    </svg-icon>
                </a>
                <a href="/">
                    <b
                        class="ml-1 pl-2 text-sm font-semibold dark:text-night-50 text-slate-50"
                    >
                        {{ appJson.displayName }}
                    </b>
                </a>
            </div>
            <a
                *ngIf="!menuService.showSideBar"
                class="flex items-center justify-center rounded p-6 focus:outline-none focus:ring-1"
            >
            </a>
            <button
                (click)="toggleSidebar()"
                class="absolute top-4 right-2 flex h-5 w-5 items-center justify-center rounded text-gray-400 transition-all duration-200 focus:outline-none hover:text-secondary dark:text-night-400 dark:hover:text-night-300"
                [ngClass]="{ 'rotate-180': !menuService.showSideBar }"
            >
                <svg-icon
                    src="assets/icons/heroicons/solid/chevron-double-left.svg"
                    [svgClass]="'h-5 w-5'"
                >
                </svg-icon>
            </button>
        </div>

        <!-- Separator -->
        <div class="pt-3">
            <hr class="border-separate border-gray-200 dark:border-night-500" />
        </div>

        <!-- Menu Items -->
        <app-sidebar-custom-menu></app-sidebar-custom-menu>
    </div>

    <div class="mx-4 my-4 space-y-1">
        <!-- Dark Theme -->
        <!-- <div
      (click)="toggleTheme()"
      class="group flex h-9 cursor-pointer items-center justify-start rounded p-2 hover:bg-gray-100 dark:hover:bg-night-600">
      <svg-icon
        [src]="
          themeService.isDark ? 'assets/icons/heroicons/outline/sun.svg' : 'assets/icons/heroicons/outline/moon.svg'
        "
        [svgClass]="'h-5 w-5 text-gray-400 dark:text-night-400'">
      </svg-icon>

      <a class="ml-3 truncate text-xs font-semibold tracking-wide text-gray-600 focus:outline-none dark:text-night-100">
        {{ themeService.isDark ? 'Light Theme' : 'Dark Theme' }}
      </a>

      <div class="fixed w-full" *ngIf="!menuService.showSideBar">
        <span
          class="z-1 absolute left-12 -top-4 w-auto min-w-max origin-left scale-0 rounded-md bg-black p-2 text-xs font-bold text-white shadow-md transition-all duration-200 group-hover:scale-100 dark:bg-white dark:text-night-900">
          Theme
        </span>
      </div>
    </div> -->
        <!-- Sign out -->
        <div
            class="group flex h-9 cursor-pointer items-center justify-start rounded p-2 hover:bg-gray-100 dark:hover:bg-night-600"
        >
            <svg-icon
                src="assets/icons/heroicons/outline/logout.svg"
                [svgClass]="'h-5 w-5 text-gray-400 dark:text-night-400'"
            >
            </svg-icon>

            <button
                (click)="logout()"
                class="ml-3 truncate text-xs font-semibold tracking-wide text-gray-600 focus:outline-none dark:text-night-100"
            >
                Sign out
            </button>

            <div class="fixed w-full" *ngIf="!menuService.showSideBar">
                <span
                    class="z-1 absolute left-12 -top-4 w-auto min-w-max origin-left scale-0 rounded-md bg-black p-2 text-xs font-bold text-white shadow-md transition-all duration-200 group-hover:scale-100 dark:bg-white dark:text-night-900"
                >
                    Sign out
                </span>
            </div>
        </div>
        <!-- Version -->
        <div
            class="group flex h-9 cursor-pointer items-center justify-start rounded p-2 hover:bg-gray-100 dark:hover:bg-night-600"
        >
            <svg-icon
                src="assets/icons/heroicons/outline/information-circle.svg"
                [svgClass]="'h-5 w-5 text-gray-400 dark:text-night-400'"
            >
            </svg-icon>

            <a
                target="_blank"
                href=""
                class="ml-3 truncate text-[10px] font-semibold tracking-wide text-gray-600 focus:outline-none dark:text-night-100"
            >
                <span
                    class="rounded-lg bg-yellow-400/10 px-2 font-semibold text-yellow-500"
                    >v{{ appJson.version }}</span
                >
            </a>

            <div class="fixed w-full" *ngIf="!menuService.showSideBar">
                <span
                    class="z-1 absolute left-12 -top-4 w-auto min-w-max origin-left scale-0 rounded-md bg-black p-2 text-xs font-bold text-white shadow-md transition-all duration-200 group-hover:scale-100 dark:bg-white dark:text-night-900"
                >
                    v{{ appJson.version }}
                </span>
            </div>
        </div>
    </div>
</nav>
