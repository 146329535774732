import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { ProfileMenuModule } from './profile-menu/profile-menu.module';
import { NavbarMobileModule } from './navbar-mobile/navbar-mobile.module';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
    declarations: [NavbarComponent],
    exports: [NavbarComponent],
    imports: [
        CommonModule,
        ProfileMenuModule,
        NavbarMobileModule,
        AngularSvgIconModule,
    ],
})
export class NavbarModule {}
