import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    constructor() {}

    parseValueText(valueText: string) {
        if (valueText !== null) {
            const [name, document, label] = valueText.split('|');
            return { name, document, label };
        }
    }
}
