import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AuthService } from '../service/auth.service';
import { environment } from 'src/environments/environment';
import { AuthorizationScopeResponse } from '../models/auth.model';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
    constructor(
        protected override readonly router: Router,
        protected readonly keycloak: KeycloakService,
        private authService: AuthService
    ) {
        super(router, keycloak);
    }

    async isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        if (!this.authenticated) {
            await this.keycloak.login({
                redirectUri: window.location.origin + state.url,
            });
            this.router.navigate(['/']);
            return false;
        }

        await this.searchPermissions(route);

        return this.authenticated;
    }

    async searchPermissions(route: ActivatedRouteSnapshot): Promise<Boolean> {
        try {
            const p = await this.keycloak.loadUserProfile();
            localStorage.setItem('keycloak::user_profile', JSON.stringify(p));

            const observer = {
                next: (permissions: AuthorizationScopeResponse[]) => {
                    if (!permissions || permissions.length === 0) {
                        console.error('Nenhuma permissão retornada');
                        this.router.navigate(['/access-denied']);
                        return false;
                    }

                    const updatedData = {
                        ...route.data,
                        userPermissions: permissions,
                        userId: p.id,
                    };
                    route.data = updatedData;

                    this.authService.setUserPermissions(permissions);

                    const requiredPermissions =
                        (route.data['permissions'] as string[]) || [];
                    if (
                        !requiredPermissions ||
                        requiredPermissions.length === 0
                    ) {
                        console.warn('Nenhuma permissão definida para a rota');
                        this.router.navigate(['/']);
                        return false;
                    }

                    const hasPermission = permissions.some((permission) =>
                        requiredPermissions.includes(permission.rsname)
                    );

                    if (!hasPermission) {
                        console.warn(
                            'Usuário não possui as permissões necessárias.'
                        );
                        this.router.navigate(['/access-denied']);
                        return false;
                    } else {
                        console.log('Acesso permitido.');
                        return true;
                    }
                },
                error: (error: any) => {
                    console.error(
                        'Erro ao obter permissões do usuário:',
                        error
                    );
                },
            };
            this.authService
                .getUserPermissions({
                    audience: environment.keycloak.audience,
                    grant_type: 'urn:ietf:params:oauth:grant-type:uma-ticket',
                    response_mode: 'permissions',
                })
                .subscribe(observer);
        } catch (e) {
            this.router.navigate(['/access-denied']);
            console.log(
                'Não foi possível armazenar os dados do usuário localmente',
                e
            );
            return false;
        }
    }
}
