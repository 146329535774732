import { NgModule } from '@angular/core';
import {
    CommonModule,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NavbarMobileMenuComponent } from './navbar-mobile-menu.component';

@NgModule({
    declarations: [NavbarMobileMenuComponent],
    imports: [
        CommonModule,
        NgFor,
        NgClass,
        AngularSvgIconModule,
        NgTemplateOutlet,
        RouterLink,
        RouterLinkActive,
        NgIf,
    ],
    exports: [NavbarMobileMenuComponent],
})
export class NavbarMobileMenuModule {}
