import { Component, OnInit } from '@angular/core';
import { SubMenuItem } from 'src/app/core/models/menu.model';
import { MenuService } from 'src/app/core/service/menu.service';

@Component({
    selector: 'app-navbar-mobile-menu',
    templateUrl: './navbar-mobile-menu.component.html',
    styleUrls: ['./navbar-mobile-menu.component.scss'],
})
export class NavbarMobileMenuComponent implements OnInit {
    constructor(public menuService: MenuService) {}

    public toggleMenu(subMenu: SubMenuItem) {
        this.menuService.toggleMenu(subMenu);
    }

    public closeMenu() {
        this.menuService.showMobileMenu = false;
    }

    ngOnInit(): void {}
}
