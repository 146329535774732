<div class="max-w-screen-xl 2xl:max-w-screen-2xl">
    <div class="customs px-6 pt-6 pb-6 grow">
        <div class="flex flex-col gap-2">
            <div class="flex gap-2">
                <button class="botao-voltar" (click)="goBack()">
                    <span class="seta-esquerda">&larr;</span>
                </button>
                <h2 class=" flex items-center font-bold text-gray-900 dark:text-white" style="font-size: 18px">
                    {{ title }}
                </h2>
            </div>
            <div class="inline-block">
                <div class="space-x-1 text-xs font-medium text-gray-400 dark:text-night-300">
                    <span class="font-bold">Início - </span>
                    <span *ngFor="let p of paths">
                        <a routerLink="{{ p.route }}" class="hover:text-primary-500 underline">{{ p.title }}</a>
                        -
                    </span>
                    <span> {{ title }}</span>
                </div>
            </div>
        </div>
        <div class="divider"></div>
        <div style="margin-bottom: 30px"></div>
        <div *ngIf="errorMessage != ''">
            <div class="flex flex-col items-center justify-center mt-4 uikit-page-error gap-3">
                <i class="pi pi-exclamation-circle" style="font-size: 1.5rem"></i>
                {{ errorMessage }}
            </div>
        </div>
        <div *ngIf="errorMessage == ''">
            <div *ngIf="isLoading">
                <div class="flex flex-col items-center justify-center uikit-page-loader mt-4 gap-3">
                    <i class="pi pi-spin pi-spinner" style="font-size: 1.5rem"></i>
                    {{ loadingMessage }}
                </div>
            </div>
        </div>

        <ng-content *ngIf="canShowContent"></ng-content>
    </div>
</div>

<uikit-messages />