import { CommonModule } from '@angular/common';
import {
    Component,
    OnInit,

} from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { UikitButton } from '../uikit-button/index.component';

interface ConfirmOptions {
    title: string,
    message: string,
    color?: string,
}


export class UikitMessagesActions {

    private static instance: UikitMessagesActions;

    private constructor() { } // Make the constructor private to prevent external instantiation

    public static getInstance(): UikitMessagesActions {
        if (!UikitMessagesActions.instance) {
            UikitMessagesActions.instance = new UikitMessagesActions();
        }

        return UikitMessagesActions.instance;
    }


    internalToastAction: (message: Message) => void | undefined
    internalConfirmAction: (options: ConfirmOptions) => Promise<boolean>



    toast(message: Message) {
        this.internalToastAction(message)
    }

    success(title: string, message: string) {
        this.internalToastAction({ severity: 'success', summary: title, detail: message, key: 'uikit-messages', life: 3000 })
    }

    primary(title: string, message: string) {
        this.internalToastAction({ severity: 'primary', summary: title, detail: message, key: 'uikit-messages', life: 3000 })
    }

    error(title: string, message: string) {
        this.internalToastAction({ severity: 'error', summary: title, detail: message, key: 'uikit-messages', life: 3000 })
    }

    async confirm(options: ConfirmOptions): Promise<boolean> {
        return await this.internalConfirmAction(options)
    }

}

@Component({
    selector: 'uikit-messages',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],
    standalone: true,
    imports: [CommonModule, ToastModule, DialogModule, UikitButton],
    providers: [MessageService]
})
export class UikitMessages implements OnInit {


    constructor(private messageService: MessageService) { }

    show() {
        this.messageService.add({ severity: 'info', summary: 'Info Message', detail: 'Message Content', key: 'uikit-messages', life: 3000 });
        console.log("toast");
    }

    confirmOptions: ConfirmOptions = {
        title: "",
        message: "",
        color: "primary"
    }

    confirmShow = false

    confirmData = {
        accept: () => { },
        reject: () => { }
    }


    enterClick = (event) => {
        if (event.key === 'Enter') {
            this.confirmData.accept();
        }
    }



    ngOnInit(): void {
        UikitMessagesActions.getInstance().internalToastAction = (message: Message) => { this.messageService.add(message) }

        UikitMessagesActions.getInstance().internalConfirmAction = (options: ConfirmOptions) => {
            this.confirmOptions = options
            this.confirmShow = true
            document.body.removeEventListener('keydown', this.enterClick);
            document.body.addEventListener('keydown', this.enterClick);
            return new Promise((accept, reject) => {
                this.confirmData.accept = async () => {
                    document.body.removeEventListener('keydown', this.enterClick);
                    this.confirmShow = false;
                    accept(true)
                }
                this.confirmData.reject = async () => {
                    document.body.removeEventListener('keydown', this.enterClick);
                    this.confirmShow = false;
                    accept(false)
                }
            })
        }
    }


}
