import { Injectable, OnDestroy, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { lastValueFrom, Subscription } from 'rxjs';
import { MenuItem, SubMenuItem } from 'src/app/core/models/menu.model';
import { AuthService } from './auth.service';
import { AuthorizationScopeResponse } from '../models/auth.model';
import { Permissions } from '../constants/permissions.constants';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MenuService implements OnDestroy {
    private _showSidebar = signal(true);
    private _showMobileMenu = signal(false);
    private _pagesMenu = signal<MenuItem[]>([]);
    private _subscription = new Subscription();
    private permissions: AuthorizationScopeResponse[] = [];

    constructor(private router: Router, private authService: AuthService) {
        this.initializeMenu();

        let sub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.updateActiveState();
            }
        });
        this._subscription.add(sub);
    }

    private async initializeMenu() {
        try {
            // Busca permissões do usuário
            this.permissions = await this.getPermission();

            // Configura o menu com base nas permissões
            const menuItems: MenuItem[] = [
                this.createMenuItem(
                    'Consulta de Veículos',
                    '/vehicles/search/clear',
                    Permissions.WEB_MODULE_LIST_VEHICLES,
                    Permissions.WEB_MODULE_VIEW,
                    'assets/icons/heroicons/outline/magnifying-glass.svg'
                ),
                this.createMenuItem(
                    'Localizações de Veículos',
                    '/locations/vehicles',
                    Permissions.WEB_MODULE_VEHICLES_LOCATIONS,
                    Permissions.WEB_MODULE_VIEW,
                    'assets/icons/heroicons/outline/map-pin.svg'
                ),
                this.createMenuItemWithChildren(
                    'Devolução',
                    '/devolucao',
                    Permissions.WEB_MODULE_VEHICLE_RETURN,
                    Permissions.WEB_MODULE_VIEW,
                    'assets/icons/heroicons/outline/backspace.svg',
                    [{ label: 'Empty', route: '/devolucao/empty' }]
                ),
                this.createMenuItemWithChildren(
                    'Sinistro',
                    '/sinistro',
                    Permissions.WEB_MODULE_VEHICLE_INSURANCE,
                    Permissions.WEB_MODULE_VIEW,
                    'assets/icons/heroicons/outline/archive-box-x-mark.svg',
                    [{ label: 'Empty', route: '/sinistro/empty' }]
                ),
                this.createMenuItemWithChildren(
                    'Dashboard',
                    '/dashboard',
                    Permissions.WEB_MODULE_DASHBOARD,
                    Permissions.WEB_MODULE_VIEW,
                    'assets/icons/heroicons/outline/chart-pie.svg',
                    [{ label: 'Utilização do Aplicativo', route: '/dashboard/app-rate' }]
                ),
                this.createMenuItemWithChildren(
                    'Relatórios',
                    '/relatorios',
                    Permissions.WEB_MODULE_RELATORIOS,
                    Permissions.WEB_MODULE_VIEW,
                    'assets/icons/heroicons/outline/presentation-chart-bar.svg',
                    [{ label: 'Empty', route: '/relatorios/empty' }]
                ),
                this.createMenuItem(
                    'Usuários e acessos',
                    '/users',
                    Permissions.WEB_MODULE_USERS_SETTINGS,
                    Permissions.WEB_MODULE_VIEW,
                    'assets/icons/heroicons/outline/users.svg'
                ),

                this.createMenuItemWithChildren(
                    'Parametrização',
                    '/parametrização',
                    Permissions.WEB_MODULE_SETTINGS,
                    Permissions.WEB_MODULE_VIEW,
                    'assets/icons/heroicons/outline/cog.svg',
                    [
                        {
                            label: 'Planilha de Rotas',
                            route: '/settings/upload-file',
                        },
                        {
                            label: 'Checklist de Recebimento',
                            route: '/settings/receipt-checklist',
                        },
                    ],
                    true
                ),

                this.createMenuItem(
                    'Perfil',
                    '/profile',
                    Permissions.WEB_MODULE_USERS_PROFILE,
                    Permissions.WEB_MODULE_VIEW,
                    'assets/icons/heroicons/outline/users.svg',
                    false
                ),
                this.createMenuItem(
                    'Ajuda',
                    '/help',
                    Permissions.WEB_MODULE_USERS_HELP,
                    Permissions.WEB_MODULE_VIEW,
                    'assets/icons/heroicons/outline/information-circle.svg',
                    false
                ),
            ];

            // Filtra os itens do menu com base nas permissões e define o estado
            const filteredMenu = this.filterMenuItems(menuItems);
            this._pagesMenu.set(filteredMenu);
        } catch (error) {
            console.error('Erro ao inicializar o menu', error);
        }
    }

    get showSideBar() {
        return this._showSidebar();
    }
    get showMobileMenu() {
        return this._showMobileMenu();
    }
    get pagesMenu() {
        return this._pagesMenu();
    }

    set showSideBar(value: boolean) {
        this._showSidebar.set(value);
    }
    set showMobileMenu(value: boolean) {
        this._showMobileMenu.set(value);
    }

    private hasMenuAccess(resource: string, scope: string): boolean {
        const resourceAccess = this.permissions.find(
            (r) => r.rsname === resource
        );
        return resourceAccess ? resourceAccess.scopes.includes(scope) : false;
    }

    private createMenuItem(
        label: string,
        route: string,
        resource: string,
        scope: string,
        icon: string,
        separator?: boolean
    ): MenuItem {
        const isActive = this.hasMenuAccess(resource, scope);

        return {
            separator: separator,
            items: [
                {
                    icon: icon,
                    label: label,
                    route: route,
                    active: isActive,
                },
            ],
            data: {
                breadcrumb: label,
            },
        };
    }

    private createMenuItemWithChildren(
        label: string,
        route: string,
        resource: string,
        scope: string,
        icon: string,
        children: SubMenuItem[],
        separator?: boolean
    ): MenuItem {
        const isActive = this.hasMenuAccess(resource, scope);

        return {
            separator: separator,
            items: [
                {
                    icon: icon,
                    label: label,
                    route: route,
                    active: isActive,
                    children: children,
                },
            ],
            data: {
                breadcrumb: label,
            },
        };
    }

    private filterMenuItems(menuItems: MenuItem[]): MenuItem[] {
        return menuItems.filter((menuItem) => {
            return menuItem.items.some((subMenu) => subMenu.active);
        });
    }

    private updateActiveState() {
        this._pagesMenu().forEach((menu) => {
            let activeGroup = false;
            menu.items.forEach((subMenu) => {
                const active = this.isActive(subMenu.route);
                subMenu.expanded = active;
                subMenu.active = active;
                if (active) activeGroup = true;
                if (subMenu.children) {
                    this.expand(subMenu.children);
                }
            });
            menu.active = activeGroup;
        });
    }

    private expand(items: Array<any>) {
        items.forEach((item) => {
            item.expanded = this.isActive(item.route);
            if (item.children) this.expand(item.children);
        });
    }

    private isActive(instruction: any): boolean {
        return this.router.isActive(this.router.createUrlTree([instruction]), {
            paths: 'subset',
            queryParams: 'subset',
            fragment: 'ignored',
            matrixParams: 'ignored',
        });
    }

    public toggleSidebar() {
        this._showSidebar.set(!this._showSidebar());
    }

    public toggleMenu(menu: any) {
        this.showSideBar = true;
        menu.expanded = !menu.expanded;
    }

    public toggleSubMenu(submenu: SubMenuItem) {
        submenu.expanded = !submenu.expanded;
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    private async getPermission(): Promise<AuthorizationScopeResponse[]> {
        try {
            const response: AuthorizationScopeResponse[] = await lastValueFrom(
                this.authService.getUserPermissions({
                    audience: environment.keycloak.audience,
                    grant_type: 'urn:ietf:params:oauth:grant-type:uma-ticket',
                    response_mode: 'permissions',
                })
            );
            this.authService.setUserPermissions(response);
            return response;
        } catch (error) {
            console.error('Erro ao obter permissões', error);
            return [];
        }
    }
}
