import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';

import { Observable, catchError, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ImportResult } from '../models/upload.model';

@Injectable()
export class UploadService {
    constructor(private http: HttpClient) {}

    // Headers
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
    };

    uploadTransportRoutes(file: File): Observable<ImportResult> {
        const url = `${environment.apiUrl}/transportroute/import`;
        const formData = new FormData();
        formData.append('file', file);
        return this.http
            .post<ImportResult>(url, formData)
            .pipe(retry(0), catchError(this.handleError));
    }

    // Manipulação de erros
    handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Erro ocorreu no lado do client
            errorMessage = error.error.message;
        } else if (error.error.text != null) {
            errorMessage = error.error.text;
        } else {
            // Erro ocorreu no lado do servidor
            errorMessage =
                'Erro na leitura do arquivo. Por favor escolha um arquivo válido e tente novamente.';
        }
        console.log(error);
        return throwError(errorMessage);
    }
}
