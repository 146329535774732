<!--
	Mobile menu, show/hide based on mobile menu state.
	Entering: "duration-200 ease-out"
		From: "opacity-0 scale-95"
		To: "opacity-100 scale-100"
	Leaving: "duration-100 ease-in"
		From: "opacity-100 scale-100"
		To: "opacity-0 scale-95"
-->
<div
    [ngClass]="
        menuService.showMobileMenu
            ? 'pointer-events-auto scale-100 animate-fade-in-up opacity-100 duration-200'
            : 'pointer-events-none scale-95 opacity-0 duration-100 ease-out'
    "
    class="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
>
    <div
        class="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-night-700"
    >
        <div class="pt-5 pb-6">
            <div class="flex items-center justify-between px-5">
                <div>
                    <!-- Logo -->
                    <div
                        class="flex items-center justify-start sm:order-2 md:mr-10 lg:hidden"
                    >
                        <a
                            class="flex items-center justify-center rounded p-2 focus:outline-none focus:ring-1"
                        >
                            <svg-icon
                                src="assets/icons/simple-icons_volkswagen-brand.svg"
                                [svgClass]="'h-9 w-9'"
                            ></svg-icon>
                        </a>
                        <b class="pl-3 text-sm font-bold dark:text-night-50">
                            Vehicle Tracking
                        </b>
                    </div>
                </div>
                <div class="-mr-2">
                    <button
                        (click)="toggleMobileMenu()"
                        type="button"
                        class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 hover:bg-gray-100 hover:text-gray-500 dark:text-night-300 dark:hover:bg-night-600 dark:hover:text-night-200"
                    >
                        <span class="sr-only">Close menu</span>
                        <!-- Heroicon name: outline/x -->
                        <svg-icon src="assets/icons/heroicons/outline/x.svg">
                        </svg-icon>
                    </button>
                </div>
            </div>
            <div
                class="scrollbar-thumb-rounded scrollbar-track-rounded max-h-[500px] overflow-y-auto px-5 scrollbar-thin scrollbar-track-gray-50 scrollbar-thumb-gray-100 dark:scrollbar-thumb-night-500 dark:scrollbar-track-night-600"
            >
                <app-navbar-mobile-menu></app-navbar-mobile-menu>
            </div>
        </div>
    </div>
</div>
