import { CommonModule } from '@angular/common';
import {
    Component,
    Output,
    EventEmitter,
    Input,
    OnInit,
    ChangeDetectorRef,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { ButtonModule } from 'primeng/button';

@Component({
    selector: 'uikit-button',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],
    standalone: true,
    imports: [ButtonModule, CommonModule, RouterModule],
})
export class UikitButton implements OnInit {

    constructor(private router: Router) {

    }

    @Input() icon: any;
    @Input() format: 'normal' | 'outlined' | 'text' = 'normal';
    @Input() typeColor: string = 'secondary';
    @Input() link: string | undefined;
    @Input() spin: boolean = false;
    @Output() onClick = new EventEmitter<any>();
    @Input() onClickPromise: () => Promise<void> | undefined

    @Input() isLoading: boolean = false;

    isLoadingInternal = false;

    ngOnInit(): void { }

    get inProcess() {
        if (this.isLoadingInternal || this.isLoading) return true
        return false;
    }

    async handleClick($event) {
        if (this.inProcess) return;
        if (this.link != null) {
            if (this.link == 'back') history.back()
            else this.router.navigate([this.link]);
        }
        if (this.onClickPromise != null) {
            this.isLoadingInternal = true;
            try {
                await this.onClickPromise()
            } catch (e) {
                this.isLoadingInternal = false;
                throw e;
            }
            this.isLoadingInternal = false;
        }
        else {
            this.onClick.emit($event);
        }
    }
}
