import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

const SECRET_KEY = environment.secretKey;

export function trimSpaces(str: string): string {
    if (str !== null && str !== undefined && str !== '') {
        return str.replace(/^\s+|\s+$/g, '');
    }
    return str;
}

export function convertVehicleLatenessStatus(
    status: string | null
): boolean | null {
    if (status === 'true') {
        return true;
    } else if (status === 'false') {
        return false;
    } else if (status === 'null') {
        return null;
    } else {
        throw new Error('Invalid status value');
    }
}

export function getEnumIndex(enumObj: any, value: string): number {
    const keys = Object.keys(enumObj);
    return keys.findIndex((key) => enumObj[key] === value);
}

export function getEnumKeyByIndex(
    enumObj: any,
    index: number
): string | undefined {
    const keys = Object.keys(enumObj);

    if (index >= 0 && index < keys.length) {
        return keys[index];
    }

    return undefined;
}

export function encryptObject(object: any): string {
    const jsonString = JSON.stringify(object);
    const encrypted = CryptoJS.AES.encrypt(jsonString, SECRET_KEY).toString();
    return encrypted;
}

export function decryptObject(encryptedString: string): any {
    const bytes = CryptoJS.AES.decrypt(encryptedString, SECRET_KEY);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
}

export function saveToSessionStorage(key: string, object: any): void {
    const encryptedObject = encryptObject(object);
    sessionStorage.setItem(key, encryptedObject);
}

export function saveToLocalStorage(key: string, object: any): void {
    const encryptedObject = encryptObject(object);
    localStorage.setItem(key, encryptedObject);
}

export function loadFromSessionStorage(key: string): any {
    const encryptedObject = sessionStorage.getItem(key);
    if (encryptedObject) {
        return decryptObject(encryptedObject);
    }
    return null;
}

export function loadFromLocalStorage(key: string): any {
    const encryptedObject = localStorage.getItem(key);
    if (encryptedObject) {
        return decryptObject(encryptedObject);
    }
    return null;
}

export function deleteSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
}

export function deleteLocalStorage(key: string): void {
    localStorage.removeItem(key);
}
