<div class="flex items-center justify-center min-h-screen bg-gray-100">
    <div class="text-center">
        <svg
            class="mx-auto mb-4 h-24 w-24 text-red-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4m0 4h.01M4.293 4.293a1 1 0 011.414 0L12 9.586l6.293-6.293a1 1 0 111.414 1.414L13.414 12l6.293 6.293a1 1 0 01-1.414 1.414L12 13.414l-6.293 6.293a1 1 0 01-1.414-1.414L10.586 12 4.293 5.707a1 1 0 010-1.414z"
            />
        </svg>
        <h1 class="text-3xl font-extrabold text-gray-800">Acesso Negado</h1>
        <p class="mt-2 text-lg text-gray-600">
            Você não tem permissão para acessar esta página.
        </p>
        <a
            href="/"
            class="mt-4 inline-block px-6 py-3 bg-secondary text-white font-semibold rounded-lg shadow-md hover:bg-primary-950 transition"
            >Voltar para o início</a
        >
    </div>
</div>
