import { APP_INITIALIZER, NgModule, LOCALE_ID } from '@angular/core';
import localePT from '@angular/common/locales/pt';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import {
    PathLocationStrategy,
    LocationStrategy,
    registerLocaleData,
} from '@angular/common';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/default/app.layout.module';
import { NotfoundComponent } from './modules/components/notfound/notfound.component';
import { CountryService } from './core/service/country.service';
import { CustomerService } from './core/service/customer.service';
import { EventService } from './core/service/event.service';
import { IconService } from './core/service/icon.service';
import { VehicleService } from './core/service/vehicle.service';
import { ReceiptService } from './core/service/receipt.service';
import { ChecklistService } from './core/service/checkList.service';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DatePipe } from '@angular/common';
import { ResponsiveHelperModule } from './shared/uikit/responsive-helper/responsive-helper.module';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { ImageService } from './core/service/image.service';
import { UtilsService } from 'src/app/core/service/utils.service';
import { UploadService } from './core/service/upload.service';
import { LayoutVwModule } from './layout/vw/layout-vw.module';

// Keycloak setup
function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
        keycloak.init({
            config: {
                url: environment.keycloak.issuer,
                realm: environment.keycloak.realm,
                clientId: environment.keycloak.clientId,
            },
            initOptions: {
                onLoad: 'login-required', // allowed values 'login-required', 'check-sso';
                flow: 'standard', // allowed values 'standard', 'implicit', 'hybrid';
            },
            enableBearerInterceptor: true,
            bearerPrefix: 'Bearer',
        });
}

registerLocaleData(localePT);
@NgModule({
    declarations: [AppComponent, NotfoundComponent],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        CountryService,
        CustomerService,
        EventService,
        IconService,
        VehicleService,
        ReceiptService,
        ChecklistService,
        SvgIconRegistryService,
        DatePipe,
        MessageService,
        TranslateService,
        ImageService,
        UtilsService,
        UploadService,
        { provide: LOCALE_ID, useValue: 'pt-br' },
    ],
    bootstrap: [AppComponent],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        LayoutVwModule,
        AngularSvgIconModule.forRoot(),
        ResponsiveHelperModule,
        KeycloakAngularModule,
    ],
})
export class AppModule {}
