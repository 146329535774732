import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './modules/components/notfound/notfound.component';
import { AccessDeniedComponent } from './modules/components/access-denied/access-denied.component';
import { AuthGuard } from './core/guard/auth.guard';
import { Permissions } from './core/constants/permissions.constants';
import { LayoutVwComponent } from './layout/vw/layout-vw.component';
import { HomeComponent } from './modules/components/home/home.component';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: '',
                    component: LayoutVwComponent,
                    children: [
                        {
                            path: '',
                            component: HomeComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: 'vehicles',
                            loadChildren: () =>
                                import(
                                    './modules/components/vehicles/vehicles.module'
                                ).then((m) => m.VehiclesModule),
                            canActivate: [AuthGuard],
                            data: {
                                permissions: [
                                    Permissions.WEB_MODULE_LIST_VEHICLES,
                                ],
                            },
                        },
                        {
                            path: 'locations',
                            loadChildren: () =>
                                import(
                                    './modules/components/locations/locations.module'
                                ).then((m) => m.LocationsModule),
                            canActivate: [AuthGuard],
                            data: {
                                permissions: [
                                    Permissions.WEB_MODULE_VEHICLES_LOCATIONS,
                                ],
                            },
                        },
                        {
                            path: 'orders',
                            loadChildren: () =>
                                import(
                                    './modules/components/orders/index.module'
                                ).then((m) => m.OrdersModule),
                            canActivate: [AuthGuard],
                            data: {
                                permissions: [
                                    Permissions.WEB_MODULE_VEHICLE_ORDER_DETAILS,
                                ],
                            },
                        },
                        {
                            path: 'dashboard',
                            loadChildren: () =>
                                import(
                                    './modules/components/dashboard/dashboard.module'
                                ).then((m) => m.DashboardModule),
                            canActivate: [AuthGuard],
                            data: {
                                permissions: [Permissions.WEB_MODULE_DASHBOARD],
                            },
                        },
                        {
                            path: 'users',
                            loadChildren: () =>
                                import(
                                    './modules/components/users/index.module'
                                ).then((m) => m.UsersModule),
                            canActivate: [AuthGuard],
                            data: {
                                permissions: [
                                    Permissions.WEB_MODULE_USERS_SETTINGS,
                                ],
                            },
                        },
                        {
                            path: 'settings',
                            loadChildren: () =>
                                import(
                                    './modules/components/settings/settings.module'
                                ).then((m) => m.SettingsModule),
                            data: {
                                permissions: [Permissions.WEB_MODULE_SETTINGS],
                            },
                        },
                    ],
                },

                { path: 'notfound', component: NotfoundComponent },
                { path: 'access-denied', component: AccessDeniedComponent },
                { path: '**', redirectTo: '/notfound' },
            ],
            {
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
                onSameUrlNavigation: 'reload',
            }
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
