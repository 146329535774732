import { NgModule } from '@angular/core';
import {
    CommonModule,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SidebarMenuComponent } from './sidebar-menu.component';
import { SidebarSubmenuModule } from '../sidebar-submenu/sidebar-submenu.module';

@NgModule({
    declarations: [SidebarMenuComponent],
    imports: [
        CommonModule,
        NgFor,
        NgClass,
        AngularSvgIconModule,
        NgTemplateOutlet,
        RouterLink,
        RouterLinkActive,
        NgIf,
        SidebarSubmenuModule,
    ],
    exports: [SidebarMenuComponent],
})
export class SidebarMenuModule {}
