import { CheckListTemplateItemDTO, OperationResult } from './checkList.module';

export interface ReceiptResult {
    operationResult: number;
    vehicleDetails?: ReceiptDetailsModel;
}


export class ReceiptDetailsModel {
    receiptId: number;
    orderItemId: number;
    corporateSalesNumber?: number;
    regionId?: number;
    billingRegionId?: number;
    invoiceNumber?: number;
    receivingSetId?: number;
    name?: string;
    chassi?: string;
    transitInDays?: number;
    orderId: number;
    receiptStatus: ReceiptStatus;
    orderCode?: string;
    reasonDescription?: string;
    orderDate?: Date;
    invoiceDate?: Date;
    estimatedDeliveryDate?: Date;
    receiptFinishedDate?: Date;
    reasonNotes?: string;
    imported?: boolean;
    signature?: string;
    signatureDocumentId?: string;
    signatureUsername?: string;
    signed?: boolean;
    signedAt?: Date;
    issuedAt: Date;
    modelCode?: string;
    salesType?: string;
    transportTypeCode?: string;
    paymentTerm?: string;
    customerName?: string;
    customerCode?: string;
    addressLine?: string;
    neighbordhood?: string;
    cityName?: string;
    stateCode?: string;
    zipCode?: string;
    buildLocalCode?: string;
    billingLocalCode?: string;
    licensePlateRegistratedAt?: Date;
    zoneCode?: string;
    checkLists?: ReceiptCheckLists[];

    constructor() { }

    get statusInfo(): { text: string } {
        const infos = {
            0: {
                text: "Não iniciado",
                color: 'var(--cancel-color)'
            },
            1: {
                text: "Em execução",
                color: 'var(--primary-color)'

            },
            2: {
                text: "Recebido / Aceito",
                color: 'var(--success-color)'
            },
            3: {
                text: "Rejeitado",
                color: 'var(--danger-color)'
            }
        }
        return infos[this.receiptStatus] ?? { text: '--', color: 'var(--cancel-color)' }
    }

    static from(data: any): ReceiptDetailsModel {
        const m = new ReceiptDetailsModel();
        for (const k of Object.keys(data)) {
            m[k] = data[k];
        }
        return m
    }
}


export interface ReceiptCheckLists {
    checkListTemplateId: number;
    checkListId?: number;
    receiptId?: number;
    name?: string;
    userName?: string;
    statusId?: number;
    statusInfo?: any;
    finishedDate?: Date;
    details?: ReceiptChecklistDetails;
    items?: CheckListTemplateItemDTO[];
}


export interface ReceiptChecklistDetails {
    "id": number,
    "checkListId": number,
    "name": string,
    "groups": {
        "id": number,
        "name": string,
        "groupCode": string,
        "groupClass": string,
        "items": {
            "id": number,
            "checkListItemId": number,
            "name": string,
            "value": string,
            "valueText": string
            "checkListItemType": number
            "optionLists": {
                "id": string,
                "name": string,
                "optionTypes": number,
                "optionListItems": {
                    "id": string,
                    "description": string,
                    "value": string,
                    "valueText": string
                }[]
            }[],
            "attachments": {
                "id": number,
                "notes": string,
                "attachment": string,
            }[]
        }[]
    }[]
}


export enum ReceiptStatus {
    NotStarted = 0,
    Started = 1,
    InProgress = 2,
    Accept = 3,
    Reject = 4,
}



export enum Status {
    NotStarted = 'NotStarted',
    Started = 'Started',
    InProgress = 'InProgress',
    Finished = 'Finished',
    Cancelled = 'Cancelled',
}

export interface GenerateReportCheckList {
    checkListId?: number;
    checkListTemplateId?: number;
}

export interface GenerateReportCheckListResponse {
    htmlReport?: string;
    operationResult?: number;
}

export interface GetChecklistAttachment {
    id?: number;
}

export interface GetChecklistAttachmentResponse {
    operationResult: OperationAttachmentResult;
    attachment?: string;
}

export enum OperationAttachmentResult {
    Failed = 0,
    Success = 1,
    NotFound = 2,
}

export const getChecklistStatusInfo = (status: number) => {
    switch (status) {
        case 0:
            return {
                text: 'Não iniciado',
                color: 'var(--cancel-color)'
            }
        case 1:
            return {
                text: 'Iniciado',
                color: 'var(--warning-color)'
            }
        case 2:
            return {
                text: 'Em progresso',
                color: 'var(--secondary-color)'
            }
        case 3:
            return {
                text: 'Finalizado',
                color: 'var(--success-color)'
            }
        case 4:
            return {
                text: 'Cancelado',
                color: 'var(--danger-color)'
            }
        default:
            return {
                text: '--',
                color: 'var(--cancel-color)'
            }
    }
}



