import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../../../core/service/menu.service';

@Component({
    selector: 'app-navbar-mobile',
    templateUrl: './navbar-mobile.component.html',
    styleUrls: ['./navbar-mobile.component.scss'],
})
export class NavbarMobileComponent implements OnInit {
    constructor(public menuService: MenuService) {}

    ngOnInit(): void {}

    public toggleMobileMenu(): void {
        this.menuService.showMobileMenu = false;
    }
}
