import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import {
    AuthorizationScopeRequest,
    AuthorizationScopeResponse,
} from '../models/auth.model';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';
import {
    BehaviorSubject,
    catchError,
    Observable,
    retry,
    throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import {
    deleteLocalStorage,
    loadFromSessionStorage,
    saveToSessionStorage,
} from '../utils/usableFuntions';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private keycloak: KeycloakService,
        private http: HttpClient,
        private route: ActivatedRoute
    ) {}

    private userPermissionsKey = 'userPermissions::';
    private userPermissions = new BehaviorSubject<AuthorizationScopeResponse[]>(
        this.loadPermissionsFromSessionStorage()
    );

    userPermissions$ = this.userPermissions.asObservable();

    userResources: AuthorizationScopeResponse[];
    userMockResources: AuthorizationScopeResponse[];

    isAdmin(): boolean {
        return this.keycloak.isUserInRole('admin');
    }

    setUserPermissions(newValue: AuthorizationScopeResponse[]) {
        this.userPermissions.next(newValue);
        saveToSessionStorage(this.userPermissionsKey, JSON.stringify(newValue));
    }

    private loadPermissionsFromSessionStorage(): AuthorizationScopeResponse[] {
        const storedUserPermissions = loadFromSessionStorage(
            this.userPermissionsKey
        );
        return storedUserPermissions ? JSON.parse(storedUserPermissions) : [];
    }

    getUserPermissions(
        params: AuthorizationScopeRequest
    ): Observable<AuthorizationScopeResponse[]> {
        const url = `${environment.keycloak.issuer}/realms/vtlog/protocol/openid-connect/token`;

        const body = new URLSearchParams();
        Object.keys(params).forEach((key) => {
            body.set(key, (params as any)[key]);
        });

        return this.http
            .post<AuthorizationScopeResponse[]>(url, body.toString(), {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
            })
            .pipe(retry(1), catchError(this.handleError));
    }

    logout() {
        deleteLocalStorage('keycloak::user_profile');
        deleteLocalStorage('vtlog:list:filters');
        deleteLocalStorage('vtlog:list:status:filters:locations');
        return this.keycloak.logout();
    }

    // Manipulação de erros
    handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Erro ocorreu no lado do client
            errorMessage = error.error.message;
        } else {
            // Erro ocorreu no lado do servidor
            errorMessage =
                `Código do erro: ${error.status}, ` +
                `menssagem: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
