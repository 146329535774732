import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/core/service/theme.service';
import packageJson from '../../../../../package.json';
import { MenuService } from 'src/app/core/service/menu.service';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
    selector: 'app-sidebar-custom',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
    public appJson: any = packageJson;

    constructor(
        public themeService: ThemeService,
        public menuService: MenuService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {}

    public toggleSidebar() {
        this.menuService.toggleSidebar();
    }

    toggleTheme() {
        this.themeService.theme = !this.themeService.isDark ? 'dark' : 'light';
    }

    logout() {
        this.authService.logout();
    }
}
