import { NgModule } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ClickOutsideDirective } from 'src/app/shared/directives/click-outside.directive';
import { ProfileMenuComponent } from './profile-menu.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
    declarations: [ProfileMenuComponent],
    imports: [
        CommonModule,
        ClickOutsideDirective,
        NgClass,
        RouterLink,
        AngularSvgIconModule,
    ],
    exports: [ProfileMenuComponent],
})
export class ProfileMenuModule {}
