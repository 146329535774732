import { Component, Input, OnInit } from '@angular/core';
import { SubMenuItem } from 'src/app/core/models/menu.model';
import { MenuService } from '../../../../core/service/menu.service';

@Component({
    selector: 'app-sidebar-custom-submenu',
    templateUrl: './sidebar-submenu.component.html',
    styleUrls: ['./sidebar-submenu.component.scss'],
})
export class SidebarSubmenuComponent implements OnInit {
    @Input() public submenu = <SubMenuItem>{};

    constructor(public menuService: MenuService) {}

    ngOnInit(): void {}

    public toggleMenu(menu: any) {
        this.menuService.toggleSubMenu(menu);
    }

    private collapse(items: Array<any>) {
        items.forEach((item) => {
            item.expanded = false;
            if (item.children) this.collapse(item.children);
        });
    }
}
