<div class="relative ml-3">
    <!-- Profile Button -->
    <button
        (clickOutside)="isMenuOpen = false"
        type="button"
        aria-expanded="false"
        aria-haspopup="true"
        (click)="toggleMenu()"
        class="flex items-center space-x-2 hover:bg-gray-100 rounded-full p-2"
    >
        <svg-icon
            src="assets/icons/heroicons/solid/chevron-right.svg"
            [svgClass]="'h-5 w-5'"
            [ngClass]="{
                'rotate-90': true
            }"
            class="filter-blue"
        >
        </svg-icon>
        <div
            class="flex text-sm font-semibold text-gray-700"
            [title]="userProfile.firstName"
        >
            {{ userProfile.firstName }}
        </div>
        <div
            class="flex w-9 h-9 rounded-full text-xl align-middle justify-center items-center font-semibold bg-gray-400 text-white"
            [title]="fullName"
        >
            {{ initials }}
        </div>
    </button>
    <!-- Dropdown -->
    <div
        [ngClass]="
            isMenuOpen
                ? 'pointer-events-auto scale-100 animate-fade-in-up opacity-100 duration-200'
                : 'pointer-events-none scale-95 opacity-0 duration-100 ease-in'
        "
        class="absolute right-0 mt-2 w-60 origin-top-right transform rounded-md bg-white py-4 shadow-custom ring-1 ring-transparent ring-opacity-5 transition focus:outline-none dark:bg-night-600"
    >
        <div class="flext-row flex items-center px-4 pb-4">
            <div class="w-10 shrink-0">
                <div
                    class="flex w-9 h-9 rounded-full text-xl align-middle justify-center items-center font-semibold bg-gray-400 text-white"
                    [title]="fullName"
                >
                    {{ initials }}
                </div>
            </div>
            <div
                class="truncate overflow-hidden px-2 text-sm font-semibold text-gray-700 dark:text-night-50"
                [title]="fullName"
            >
                {{ fullName }}
                <p
                    class="truncate text-ellipsis text-xs font-semibold text-gray-400 dark:text-night-300"
                    [title]="userProfile.email"
                >
                    {{ userProfile.email }}
                </p>
            </div>
        </div>

        <div
            class="border-b border-dashed border-gray-200 dark:border-night-500"
        ></div>

        <ul class="my-2 mx-4">
            <li
                class="block cursor-pointer rounded-md px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100 hover:text-primary-500 dark:text-night-200 dark:hover:bg-night-500 dark:hover:text-primary-500"
            >
                Your Profile
            </li>
            <li
                class="block cursor-pointer rounded-md px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100 hover:text-primary-500 dark:text-night-200 dark:hover:bg-night-500 dark:hover:text-primary-500"
            >
                Language
            </li>
            <li
                class="block cursor-pointer rounded-md px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100 hover:text-primary-500 dark:text-night-200 dark:hover:bg-night-500 dark:hover:text-primary-500"
            >
                Settings
            </li>
            <button
                (click)="logout()"
                class="w-full block cursor-pointer rounded-md px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100 hover:text-primary-500 dark:text-night-200 dark:hover:bg-night-500 dark:hover:text-primary-500"
            >
                Sign out
            </button>
        </ul>
    </div>
</div>
