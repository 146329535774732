import { NgModule } from '@angular/core';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SidebarComponent } from './sidebar.component';
import { SidebarMenuModule } from './sidebar-menu/sidebar-menu.module';

@NgModule({
    declarations: [SidebarComponent],
    exports: [SidebarComponent],
    imports: [
        CommonModule,
        NgClass,
        NgIf,
        AngularSvgIconModule,
        SidebarMenuModule,
    ],
})
export class SidebarModule {}
