import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../../core/service/menu.service';

@Component({
    selector: 'app-navbar-vw',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    constructor(private menuService: MenuService) {}

    ngOnInit(): void {}

    public toggleMobileMenu(): void {
        this.menuService.showMobileMenu = true;
    }
}
